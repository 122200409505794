import React from "react";
import "./Fold.scss";
import leftFoldImage from "./images/left-image-fold.webp";
import rightFoldImage from "./images/right-image-fold.webp";
import leftImageBg from "./images/left-image.webp";

function Fold() {
  return (
    <div className="relative p-top-12 p-bottom-12 p-top-6-xs p-bottom-6-xs">
        <img
          className="bg-fold-left"
          src={leftFoldImage}
          alt=""
        ></img>
        <img
          className="bg-fold-right"
          src={rightFoldImage}
          alt=""
        ></img>
        <img
          className="bg-fold-left-back"
          src={leftImageBg}
          alt=""
        ></img>
      <div className="relative container p-top-11-xs p-top-11-sm">
        <div className="text-center p-top-4 p-top-no-xs p-top-no-sm">
            <h3 className="text-primary text-shadow">Ние,</h3>
            <h1>Любомира &#38; Александър</h1>
        </div>
        <h4 className="font-heading text-center p-bottom-6 m-top-4">
          С радост Ви каним да споделите с нас Деня,{" "}
          <br className="hidden-xs" />в който ще се наречем{" "}
          <span className="text-primary text-bold text-shadow">Съпруг</span>
          {" "} и {" "}
          <span style={{color: "#fca9bc"}} className="text-bold text-shadow">Съпруга</span>!
        </h4>
        <h1 className="text-center p-bottom-2 p-bottom-4-xs m-bottom-12 ">25 Септември, 2025г.</h1>
      </div>
      <div className="flex">
        <div
          className="col-12"
        ></div>
      </div>
    </div>
  );
}

export default Fold;
