import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import RingsIcon from "./images/rings.webp";

function Schedule() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
              <i className="fa-solid fa-martini-glass-citrus m-right-1 text-xl"></i>
                <span className="text-primary text-shadow">17:00</span>
              </h2>
              <h3 className="m-bottom-1">Коктейл за Добре дошли</h3>
              <p className="m-bottom-1">
                Английският двор на комплекс "Хоризонт"
              </p>
              <a
                className="see-more m-top-2 text-primary"
                href="#kokteilzadobredoshli"
              >
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-0"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-17"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
              <img
                  alt=""
                  src={RingsIcon}
                  style={{ width: 30 }}
                  className="flex m-right-1 text-xl"
                />
                <span className="text-primary text-shadow">17:30</span>
              </h2>
              <h3 className="m-bottom-1">Изнесен ритуал</h3>
              <p className="m-bottom-1">
                Стига вече веселба, време е да кажем "ДА!". 
              </p>
              <a
                className="see-more m-top-2 text-primary"
                href="#iznesenritual"
              >
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-17"></div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <i className="fa-brands fa-fort-awesome m-right-1 text-xl" />
                <span className="text-primary text-shadow">18:30</span>
              </h2>
              <h3 className="m-bottom-1">Сватбено тържество</h3>
              <p className="m-bottom-1">
                Ресторант "Хоризонт"
              </p>
              <a className="see-more m-top-2 text-primary" href="#restorant">
                Виж повече
              </a>
            </div>
            <div className="timeline-clock">
              <div className="timeline-arrow-minutes timeline-arrow-minutes-30"></div>
              <div className="timeline-clock-circle"></div>
              <div className="timeline-arrow-hour timeline-arrow-hour-18"></div>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default Schedule;
