import React from "react";
import KaloyanovaKrepost from "./images/restorant.webp";

function Restaurant() {
  return (
    <div
      className="restaurant bg-base p-top-8 p-bottom-8"
      id="restorant"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-brands fa-fort-awesome m-right-1 text-xl" />
            <span className="text-primary">18:30</span>
          </h2>
          <h1 className="m-bottom-1">Сватбено тържество</h1>
        </div>
        <div>
          <img
            alt="Krajba na Bulka"
            className="display-block m-auto"
            src={KaloyanovaKrepost}
            style={{ width: "100%", maxWidth: 600 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1 m-top-2 m-bottom-2 text-justify">
            И нека веселието продължи в ресторант <span className="text-bold">"Хоризонт"</span>.
          </p><p className="text-justify">
            Тук ви очаква не просто вечеря, а и няколко изненади. А за целите на първата – ще се наложи да ви я издадем. Не искаме да има неловко мълчание по масите с непознати хора, затова ще разчупим леда!
            <br /><br />
            На всяка маса ще има малко по-различно „Бинго“ – <span className="text-bold">„Weddingo“</span>. При потвърждаване на присъствието във формичката по-надолу, моля, напишете нещо за себе си, което ви отличава и което нямате нищо против останалите на масата да знаят.
            <br /><br />
            Първият, който направи „Бинго“, печели второ парче торта! <i className="fa-cake-candles fa-solid m-left-1 text-md" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
