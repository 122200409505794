import React from "react";

const mapEmbededRestaurant = `<div style="width: 100%"><iframe width="520" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=520&amp;height=320&amp;hl=en&amp;q=%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81%20%D0%A5%D0%BE%D1%80%D0%B8%D0%B7%D0%BE%D0%BD%D1%82,%20%D0%9F%D1%80%D0%B8%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B8%20%D0%BF%D0%B0%D1%80%D0%BA,%209000%20Varna+(Horizont%20Complex)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">buy drones</a></iframe></div>`;
const mapEmbededMobileRestaurant = `<div style="width: 100%"><iframe width="310" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=310&amp;height=320&amp;hl=en&amp;q=%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81%20%D0%A5%D0%BE%D1%80%D0%B8%D0%B7%D0%BE%D0%BD%D1%82,%20%D0%9F%D1%80%D0%B8%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B8%20%D0%BF%D0%B0%D1%80%D0%BA,%209000%20Varna+(Horizont%20Complex)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">best drones</a></iframe></div>`;
const mapEmbededCocktails = `<div style="width: 100%"><iframe width="520" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=520&amp;height=320&amp;hl=en&amp;q=Boutique%20complex%20Horizant,%20%D0%BF%D1%80%D0%B8%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B8%20%D0%BF%D0%B0%D1%80%D0%BA,%20%D0%BF%D0%BB.%203,%209010%20%D0%92%D0%B0%D1%80%D0%BD%D0%B0+(Boutique%20complex%20Horizant)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">best drones</a></iframe></div>`;
const mapEmbededCocktailsMobile = `<div style="width: 100%"><iframe width="310" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=310&amp;height=320&amp;hl=en&amp;q=Boutique%20complex%20Horizant,%20%D0%BF%D1%80%D0%B8%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B8%20%D0%BF%D0%B0%D1%80%D0%BA,%20%D0%BF%D0%BB.%203,%209010%20%D0%92%D0%B0%D1%80%D0%BD%D0%B0+(Boutique%20complex%20Horizant)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">drone quadcopter</a></iframe></div>`;
const mapEmbededPlanB = `<div style="width: 100%"><iframe width="520" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=520&amp;height=320&amp;hl=en&amp;q=%D0%92%D0%B5%D1%80%D0%B0%D0%BD%D0%B4%D0%B0,%20%D0%9C%D0%BE%D1%80%D1%81%D0%BA%D0%B0%20%D0%93%D1%80%D0%B0%D0%B4%D0%B8%D0%BD%D0%B0,%20Complex%20Horizont,%20Sea%20Garden,%209000%20%D0%92%D0%B0%D1%80%D0%BD%D0%B0+(Veranda,%20Complex%20Horizont)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">buy drones</a></iframe></div>`;
const mapEmbededPlanBMobile = `<div style="width: 100%"><iframe width="310" height="320" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=310&amp;height=320&amp;hl=en&amp;q=%D0%92%D0%B5%D1%80%D0%B0%D0%BD%D0%B4%D0%B0,%20%D0%9C%D0%BE%D1%80%D1%81%D0%BA%D0%B0%20%D0%93%D1%80%D0%B0%D0%B4%D0%B8%D0%BD%D0%B0,%20Complex%20Horizont,%20Sea%20Garden,%209000%20%D0%92%D0%B0%D1%80%D0%BD%D0%B0+(Veranda,%20Complex%20Horizont)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/collections/drones/">drones ireland</a></iframe></div>`;

export default function Transport() {
  const isMobile = window && window.innerWidth < 600;
  return (
    <div className="p-top-4 p-bottom-12 transport transport--bg" id="transport">
      <div className="container p-top-8">
        <h1 className="text-center m-bottom-4">Как да стигна?</h1>
        <div className="flex flex-justify-center">
          <div className="col-12 col-8-xl">
            <p className="text-md m-bottom-2 text-center">
              Тук може да намерите GPS координатите на всички локации от Сватбата.
            </p>
          </div>
        </div>
        <div className="flex flex-justify-center m-top-8">
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Ресторант "Хоризонт"</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededRestaurant }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededMobileRestaurant }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-6-xl">
            <h2 className="text-center">Изнесен Ритуал и хотел</h2>
            <p className="text-xs text-center">(Цъкни върху "Directions")</p>
            <div className="flex flex-justify-center m-top-1 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededCocktails }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededCocktailsMobile,
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-12 col-6-xl m-top-6 m-top-no-xs m-top-no-sm">
            <h2 className="text-center">Резервен Вариант - "Веранда"</h2>
            <p className="text-sm text-center m-top-1">В случай, че времето не е на наша страна, изнесеният ритуал и коктейлът за добре дошли ще се състоят на територията на "Веранда", в същия комплекс.</p>
            <div className="flex flex-justify-center m-top-2 m-bottom-5">
              {!isMobile && (
                <div
                  className="map-location hidden-xs hidde-sm shadow-default"
                  dangerouslySetInnerHTML={{ __html: mapEmbededPlanB }}
                />
              )}
              {isMobile && (
                <div
                  className="map-location hidden-md hidden-lg hidden-xl shadow-default"
                  dangerouslySetInnerHTML={{
                    __html: mapEmbededPlanBMobile,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
