import React from "react";
import "./EngagedSection.scss";

export default function RingsSection() {
  return (
    <div className="relative">
    <div className="container">
    <div className="flex flex-align-center flex-justify-end-md flex-justify-center m-top-4 m-bottom-4 relative">
      <div className="col-12-xs col-12-sm col-7">
        <div className="text-lg text-md-xs m-top-4">Стана ясно - ЩЕ СЕ ЖЕНИМ!</div>
        <div className="text-lg text-md-xs m-top-2">
          Най-накрая оцелях, след 12 години учене, без да ме изгонят (чудо, нали?), и сега е време да го отпразнуваме като истински шампиони! Заповядайте на моя абитуриентски бал – хапване, пийване, танци и смях, а аз ще се постарая да изглеждам толкова сериозен, че да повярвате, че съм зрял възрастен... поне за един ден!
          <br />
          <br />
          Важни уточнения:
            <br/>
            <br/>
:rocket: Да празнуваме като за последно, но без системни сривове!
            <br/>
:joy: Дипломата е истинска, въпреки че понякога учителите може би са имали съмнения.
            <br/>
:camera_with_flash: Ако видите странни танци – не, не е бъг, просто доброто настроение взема превес!
          <br />
          За нас ще бъде чест да присъствате!
          <br />
          <br />
          Молим да потвърдите вашето присъствие до{" "}
          <span className="text-hover text-bold">
            <br className="hidden-xs hidden-sm" />
            01 Май, 2025г.
          </span>{" "}
          като попълните формуляра в края на тази страница.
        </div>
        <div className="m-top-4 m-bottom-4">
          <a href="#confirm" className="confirm-btn full-width-xs">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}
