import React from "react";
import "./Schedule.scss";
import FadeInSection from "./FadeInSection";
import ChildImage2 from "./images/liq-baby.webp";
import ChildImage1 from "./images/sasho-baby.webp";

function History() {
  return (
    <div className="bg-base">
      <div className="timeline p-top-8 p-bottom-8">
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <div>
                <img alt="" src={ChildImage1} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">16.03.1991г.</span>
              </h2>
              <h3 className="m-bottom-1">
                Роди се Александър
                <i className="fa-solid fa-baby m-left-2 text-xl"></i>
              </h3>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <div>
                <img alt="" src={ChildImage2} className="family-photo"></img>
              </div>
              <h2 className="flex flex-align-center">
                <span className="text-primary">03.06.2000г.</span>
              </h2>
              <h3 className="m-bottom-1">Роди се Любомира</h3>
            </div>
            <div className="timeline-circle"></div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h4 className="m-bottom-1">
                Вярвате ли в Ефекта на пеперудата?
              </h4>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">21.09.2015г.</span>
              </h2>
              <h3 className="m-bottom-1">Тя тръгна на фехтовка</h3>
              <p className="m-bottom-1">“Спортът не е за мен, аз съм човек на изкуството!” твърдеше тя, докато не прочете поредицата “Скъпоценни камъни” и не реши да пробва този така интригуващо описан спорт, който и без друго вече тренираха брат й и баща й.</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
            <h2 className="flex flex-align-center">
                <span className="text-primary">03.11.2016г.</span>
              </h2>
              <h3 className="m-bottom-1">Той тръгна на суинг</h3>
              <p className="m-bottom-1">Вече завърнал се в България, след тежко следване в чужбина, Сашо има за задача да приравни дипломата си и да свали натрупаните килограми от вредната храна в Англия. “Винаги съм обичал музиката и това е чудесен начин да вляза във форма.”</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
           <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">04.09.2017г.</span>
              </h2>
              <h3 className="m-bottom-1">Той тръгна на салса в “Сабросо”</h3>
              <p className="m-bottom-1">Да, само че, поради слабия интерес към този стил танци, групата не просъществува. След кратка, или не чак толкова кратка, консултация с негов приятел от суинга, Сашо решава да пробва салса в клуб “Сабросо”.</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">27.05.2019г.</span>
              </h2>
              <h3 className="m-bottom-1 flex flex-align-center">
                Абитуриентският бал на Лия
              </h3>
              <p className="m-bottom-1">Дългогодишната й съотборничка от клуба по фехтовка й подарява маслени бои, с пожеланието “Нека обагрят живота ти!”. Да, само че към този момент клетото момиче е рисувало само с моливи и изпитва ужас от четките.</p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">17.09.2019г.</span>
              </h2>
              <h3 className="m-bottom-1">Рисуване и вино</h3>
              <p className="m-bottom-1">
                Лия вече е направила 2 неуспешни опита да изпозва тези боички в домашни условия. (Последиците ще останат запечатани за вечни времена под формата на едно платно почти черна “пролетна” картина и един неприятно син дракон, нарисуван на стената в детската й стая.) Тя решава да си подари за имения ден арт терапия - рисуване с вино. И изненада! С художничката споделят един имен ден. Това води до оживен женски разговор и Лия се оказва поканена в новата й начинаеща група по танци.
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">07.10.2019г.</span>
              </h2>
              <h3 className="m-bottom-1">Запознаство</h3>
              <p className="m-bottom-1">
                И така… Тя се озовава в залата по танци на “Сабросо”. Един дребен полу-гол мъж, с пънкарска прическа размахва нинчако точно под табелата “Айкидо”. Напълно убедена, че е объркала залата, тя тръгва да си ходи, когато полу-голият мъж я догонва и я информира, че е това е точното място. Леко озадачена се връща в мазето. Към същият момент все още обмисля колко добра идея е било това... И тогава Лия забелязва едно симпатично момче, танцуващо в другия край на залата с млада дама. “Хм, добре. Тук може и да поостана”. 
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">30.10.2019г.</span>
              </h2>
              <h3 className="m-bottom-1">Първа среща</h3>
              <p className="m-bottom-1">
              На нея й хареса салсата, но уплашена от някои хора в залата, Лия решава да си търси нов клуб. Напълно отчаян, че новото момиче ще напусне от групата им и пак ще останат без дами, Сашо я кани на среща. Тя приема и по стечение на обстоятелствата, тази среща се състои точно на двадесетата годишнина от сватбата на родителите й. Тук също имаме добра история, но нека запазим нещо за водещата на сватбата.
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">17.12.2023г.</span>
              </h2>
              <h3 className="m-bottom-1">Наш дом</h3>
              <p className="m-bottom-1">
              След като цяло лято са прекарали в ремонтни дейности, младата двойка най-накрая е постигнала първата си голяма мечта заедно - място, което да нарекат “вкъщи”.
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="timeline-container right">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">05.10.2024г.</span>
              </h2>
              <h3 className="m-bottom-1">Годеж</h3>
              <p className="m-bottom-1">
                Той търсеше тихо и усамотено място, където да зададе най-трудния въпрос, който някога е задавал. След дълго коментиране с приятели и обсъждане с началниците за примерна реч - той избира нос Калиакра като подходяща локация. Естествено, точно в този ден отново нищо не върви по план, но и тази история ще оставим за сватбата. Накратко - тя каза “Да!”, докато спътниците им са натоварени с тежката задача да разгонят Нова телевизия от предложението им.
              </p>
            </div>
          </div>
        </FadeInSection>        
        <FadeInSection>
          <div className="timeline-container left">
            <div className="content shadow-default">
              <h2 className="flex flex-align-center">
                <span className="text-primary">25.09.2025г.</span>
              </h2>
              <h3 className="m-bottom-1">Сватба</h3>
              <p className="m-bottom-1">
                Сега се намираме тук - на прага на най - важния ден в живота ни, който Ви каним да споделите с нас. :)
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default History;
