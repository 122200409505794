import React from "react";
import "./Content.scss";
import EngagedSection from "./EngagedSection";
import ParallaxPhoto from "./ParallaxPhoto";
import Schedule from "./Schedule";
import KrajbaNaBulkata from "./KrajbaNaBulkata";
import LqskovskiManastir from "./LqskovskiManastir";
import Transport from "./Transport";
import GrajdanskiBrak from "./GrajdanskiBrak";
import Restaurant from "./Restaurant";
import History from "./History";
import Godparents from "./Godparents";
import Counter from "./Counter";
import Questions from "./Questions";
import ConfirmationContainer from "./ConfirmationContainer";
import imagePerson from "./images/lyubo.webp";

function Content() {
  return (
    <div className="relative content-custom-margin">
      <div className="bg-engaged-section relative">
      <img
        alt="Engaged photo2"
        className="section-left-image"
        src={imagePerson}
        style={{width: "auto", height: "100%"}}

      ></img>
      <div className="flex">
        <div
          className="col-12 p-top-0 bg-secondary"
        ></div>
      </div>
        <EngagedSection />
        <div className="flex">
        <div
          className="col-12 p-top-0 bg-secondary"
        ></div>
      </div>
      </div>
      <ParallaxPhoto photoClass="parallax-engaged">
        <h1 id="schedule" className="text-white text-center">
          ПРОГРАМА
        </h1>
      </ParallaxPhoto>
      <Schedule />
      {/* <ParallaxPhoto photoClass="parallax-arbanasi">
        <br />
        <h3 className="text-white text-center p-left-1 p-right-1">
          „Има само едно щастие в живота– да обичаш и да бъдеш обичан.“
        </h3>
        <br />
      </ParallaxPhoto> */}
      <KrajbaNaBulkata />
      <GrajdanskiBrak />
      <LqskovskiManastir />
      <Restaurant />
      <Godparents />
      <Transport />
      <Counter />
      <ParallaxPhoto photoClass="parallax-our-story">
        <br />
        <h1 id="history" className="text-white text-center">
          НАШАТА ИСТОРИЯ
        </h1>
        <br />
      </ParallaxPhoto>
      <History />
      <Questions />
      <ConfirmationContainer />

      <div className="p-top-12 p-top-4-xs bg-white p-bottom-6 text-center">
        <h5>с обич:</h5>
        <h3>сем. Апостолови</h3>
        <div className="heart m-auto"></div>
        <div className="container m-auto flex p-top-12 p-top-8-xs">
          <hr className="flex flex-justify-center col-12-xs col-12-sm col-8 hr-style m-top-2 m-bottom-2 m-left-auto m-right-auto" />
        </div>
        <div>Copyright ©2025 Абитуриентска покана</div>
        <div>
          Автор: Любослав
          <i className="m-left-1 fa-solid fa-hand-holding-heart"></i>
        </div>
      </div>
    </div>
  );
}

export default Content;
