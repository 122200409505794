import React from "react";
import "./Content.scss";
import EngagedSection from "./EngagedSection";
import ParallaxPhoto from "./ParallaxPhoto";
import Schedule from "./Schedule";
import Transport from "./Transport";
import GrajdanskiBrak from "./GrajdanskiBrak";
import Restaurant from "./Restaurant";
import History from "./History";
import Counter from "./Counter";
import Questions from "./Questions";
import ConfirmationContainer from "./ConfirmationContainer";
import EngagedSectionSlider from "./EngagedSectionSlider";
import WelcomeDrinks from "./WelcomeDrinks";

function Content() {
  return (
    <div className="relative">
      <div className="bg-engaged-section">
      <div className="flex">
        <div
          className="col-12 p-top-0 bg-secondary"
        ></div>
      </div>
        <div className="container">
          <div className="flex">
            <div className="col-12">
              <EngagedSection />
            </div>
            <div className="slider hidden-md hidden-lg hidden-xl col-auto m-auto p-bottom-6 slider-mobile p-right-2-xs p-left-2-xs">
              <EngagedSectionSlider width={288} height={411} />
            </div>
          </div>
        </div>
        <div className="flex">
        <div
          className="col-12 p-top-0 bg-secondary"
        ></div>
      </div>
      </div>
      <ParallaxPhoto photoClass="parallax-engaged">
        <h1 id="schedule" className="text-white text-center">
          ПРОГРАМА
        </h1>
      </ParallaxPhoto>
      <Schedule />
      <ParallaxPhoto photoClass="parallax-quotes">
        <br />
        <h3 className="text-white text-center p-left-1 p-right-1">
          „Има само едно щастие в живота – да обичаш и да бъдеш обичан.“
        </h3>
        <br />
      </ParallaxPhoto>
      <WelcomeDrinks />
      <GrajdanskiBrak />
      <Restaurant />
      {/* <Godparents /> */}
      <Transport />
      <Counter />
      <ParallaxPhoto photoClass="parallax-our-story">
        <br />
        <h1 id="history" className="text-white text-center">
          НАШАТА ИСТОРИЯ
        </h1>
        <br />
      </ParallaxPhoto>
      <History />
      <Questions />
      <ConfirmationContainer />

      <div className="p-top-12 p-top-4-xs bg-white p-bottom-6 text-center">
        <h5>с обич:</h5>
        <h3>Лия &amp; Сашо</h3>
        <div className="heart m-auto"></div>
        <div className="container m-auto flex p-top-12 p-top-8-xs">
          <hr className="flex flex-justify-center col-12-xs col-12-sm col-8 hr-style m-top-2 m-bottom-2 m-left-auto m-right-auto" />
        </div>
        <div>Copyright ©2025 Сватбена покана</div>
        <div>
          Автор: Булката
          <i className="m-left-1 fa-solid fa-hand-holding-heart"></i>
        </div>
      </div>
    </div>
  );
}

export default Content;
