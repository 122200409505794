import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/main.scss";
import Header from "./components/Header";
import Fold from "./components/Fold";
import Content from "./components/Content";
import Header2 from "./components/rozi-and-moni/Header";
import Fold2 from "./components/rozi-and-moni/Fold";
import Content2 from "./components/rozi-and-moni/Content";
import Header3 from "./components/desi-and-viktor/Header";
import Fold3 from "./components/desi-and-viktor/Fold";
import Content3 from "./components/desi-and-viktor/Content";
import Header4 from "./components/lyubomira-and-aleksandur/Header";
import Fold4 from "./components/lyubomira-and-aleksandur/Fold";
import Content4 from "./components/lyubomira-and-aleksandur/Content";

import Header5 from "./components/abiturient-lyuboslav/Header";
import Fold5 from "./components/abiturient-lyuboslav/Fold";
import Content5 from "./components/abiturient-lyuboslav/Content";

import ComingSoon from "./components/images/coming-soon.webp";

function App() {
  return (
    <div className="MyWedding">
      <Router>
        <Routes>
          {/* Add your routes here */}
          <Route path="/" element={<GiziAndDido />} />
          <Route path="/gizi-and-dido" element={<GiziAndDido />} />
          <Route path="/rozi-and-moni" element={<RoziAndMoni />} />
          <Route path="/desi-and-viktor" element={<DesiAndViktor />} />
          <Route path="/lia-and-sasho" element={<LyubomiraAndAleksandur />} />
          <Route path="/lyubomira-and-aleksandur" element={<LyubomiraAndAleksandur />} />
          <Route path="/maria-and-krasimir" element={<div className="flex flex-align-center" style={{ backgroundColor: "#9E9E9E", width: "100%", height: "100vh" }}>
            <img src={ComingSoon} alt="Maria and Krasimir" style={{width: "100%", minWidth: "100%", height: "auto", border: "4px solid white", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.2), 4px 4px 0px rgba(255, 255, 255, 0.2)"}}/>
          </div>} />
          <Route path="/spoko-samo-za-bal-na-lyubo-e" element={<AbiturientLyuboslav />} />
        </Routes>
      </Router>
    </div>
  );
}

// GiziAndDido component for the "/gizi-and-dido" path
function GiziAndDido() {
  return (
    <div id="gizi-and-dido">
      <div className="bg-fold">
        <Header />
        <Fold />
      </div>
      <Content />
    </div>
  );
}

function RoziAndMoni() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_ROZI_AND_MONI);
  }, []);

  return (
    <div id="rozi-and-moni">
      {/* <div className="flex" style={{height: 100}}>
          <div className="col" style={{background: "#5375a3", height: 100}}>primary, link</div>
          <div className="col" style={{background: "#C8AAAF", height: 100}}>secondary</div>
          <div className="col" style={{background: "#587555", height: 100}}>third</div>
          <div className="col" style={{background: "#ede5e4", height: 100}}>base</div>
          <div className="col" style={{background: "#AABFA6", height: 100}}>--border-color</div>
          <div className="col" style={{background: "#A7B8CE", height: 100}}></div>
          <div className="col" style={{background: "#9CA7A1", height: 100}}></div>
          <div className="col" style={{background: "#252D52", height: 100}}></div>
      </div> */}
      <div className="bg-fold-gradient">
        <Header2 />
        <Fold2 />
      </div>
      <Content2 />
    </div>
  );
}

const COLOR_VARIABLES_ROZI_AND_MONI = {
  "--border-color": "#AABFA6",
  "--primary-color": "#5375a3",
  "--secondary-color": "#C8AAAF",
  "--third-color": "#587555",
  "--hover-color": "#A7B8CE",
  "--link-color": "#5375a3",
  "--text-color": "#384347"
};

function DesiAndViktor() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_DESI_AND_VIKTOR);
  }, []);

  return (
    <div id="desi-and-viktor">
      <div className="bg-fold">
        <Header3 />
        <Fold3 />
      </div>
      {/* <div className="flex" style={{height: 100}}>
          <div className="col" style={{background: "#38807Fff", height: 100}}>--border-color</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>primary, link</div>
          <div className="col" style={{background: "#C8E2F1ff", height: 100}}>secondary</div>
          <div className="col" style={{background: "#F1D6D6", height: 100}}>third</div>
          <div className="col" style={{background: "#ede5e4", height: 100}}>base</div>
          <div className="col" style={{background: "#A7B8CE", height: 100}}>hover</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>link color</div>
      </div> */}
      <Content3 />
    </div>
  );
}

const COLOR_VARIABLES_DESI_AND_VIKTOR = {
  "--border-color": "#38807Fff",
  "--primary-color": "#38807Fff",
  "--secondary-color": "#C8E2F1ff",
  "--third-color": "#F1D6D6",
  "--hover-color": "#A7B8CE",
  "--link-color": "#38807Fff",
};

function LyubomiraAndAleksandur() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_LYUBOMIRA_AND_ALEKSANDUR);
  }, []);

  return (
    <div id="lyubomira-and-aleksandur">
      <div className="bg-fold">
        <Header4 />
        <Fold4 />
      </div>
      <Content4 />
    </div>
  );
}

const COLOR_VARIABLES_LYUBOMIRA_AND_ALEKSANDUR = {
  "--border-color": "#A1C5E8",
  "--primary-color": "#A1C5E8",
  "--secondary-color": "#E1D4E9",
  "--third-color": "#BFB8DB",
  "--hover-color": "#fca9bc",
  "--link-color": "#fca9bc",
};


function AbiturientLyuboslav() {
  // Use useEffect to update CSS variables when the component mounts
  useEffect(() => {
    applyColorVariables(COLOR_VARIABLES_ABITURIENT_LYUBOSLAV);
  }, []);

  return (
    <div id="abiturient-lyuboslav">
      <div className="bg-white">
        <Header5 />
        <Fold5 />
      </div>
      {/* <div className="flex" style={{height: 100}}>
          <div className="col" style={{background: "#38807Fff", height: 100}}>--border-color</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>primary, link</div>
          <div className="col" style={{background: "#C8E2F1ff", height: 100}}>secondary</div>
          <div className="col" style={{background: "#F1D6D6", height: 100}}>third</div>
          <div className="col" style={{background: "#ede5e4", height: 100}}>base</div>
          <div className="col" style={{background: "#A7B8CE", height: 100}}>hover</div>
          <div className="col" style={{background: "#38807Fff", height: 100}}>link color</div>
      </div> */}
      <Content5 />
    </div>
  );
}

const COLOR_VARIABLES_ABITURIENT_LYUBOSLAV = {
  "--border-color": "#38807Fff",
  "--primary-color": "#000000",
  "--secondary-color": "#9cb2dc",
  "--third-color": "#e3e9f5",
  "--hover-color": "#A7B8CE",
  "--link-color": "#38807Fff",
};


// Function to apply variables to the document
function applyColorVariables(colorVariables) {
  Object.entries(colorVariables).forEach(([variable, value]) => {
    document.documentElement.style.setProperty(variable, value);
  });
}


export default App;
