import React from "react";
import "./Fold.scss";
import bottomFoldImage from "./images/fold-bottom-image.svg";
import foldIntroImage from "./images/prom-intro.svg";

function Fold() {
  return (
    <div className="relative p-bottom-6 p-bottom-4-xs p-top-4-xs p-top-4-sm p-top-4-md p-top-4-xl p-bottom-12-xl p-bottom-10-lg p-bottom-10-lg">
      <img
        className="bg-fold-bottom"
        src={bottomFoldImage}
        alt=""
      ></img>
      <div className="relative container">
        <div className="text-center">
          <img
          className="fold-intro-image"
          src={foldIntroImage}
          alt="Балът на Любо"
          style={{
            width: "100%",
            height: "auto",
          }}
        ></img>
        </div>
        <div className="col-12-xs col-12-sm col-9 m-left-auto m-right-auto">
          <h4 className="font-heading text-center p-bottom-2 m-top-4">
            <div className="m-bottom-2">Скъпи приятели и роднини,</div>
            Имам честта и удоволствието да ви поканя на моя абитуриентски бал, където ще споделим радостта, спомените и мечтите за бъдещето! 
          </h4>
        </div>
        <div className="m-top-1 m-bottom-2 text-center">
          <a href="#confirm" className="confirm-btn full-width-xs">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
        <h1 className="text-center p-bottom-2-xs m-bottom-12 text-primary">24 Май, 2025г.</h1>
      </div>
      <div className="flex">
        <div
          className="col-12"
        ></div>
      </div>
    </div>
  );
}

export default Fold;
