import React from "react";
import BulgarianRoses from "./images/bulgarian-roses.webp";

function Questions() {
  return (
    <div className="bg-page">
      <section className="container text-center p-top-8 p-bottom-8">
        <div className="flex flex-justify-center">
          <h1 className="col-12 text-center">25 Септември, 2025г.</h1>
          <div className="col-5 col-8-xs">
            <img
              style={{ width: "100%" }}
              src={BulgarianRoses}
              alt="Bulgarian Roses"
            ></img>
          </div>
          <div className="col-12-xs col-12-sm col-8">
            <h2 className="m-bottom-2 text-primary">
              С кого да се свържа в деня на Сватбата?
            </h2>
            <div className="text-lg p-right-10-xl p-left-10-xl">
              При възникнали въпроси от всякакво естество в деня на сватбата, можете да се свържете с нашия сватбен агент:{" "}
            </div>
            <p className="m-top-2 m-bottom-2 text-lg m-bottom-8">
              Симона - тел.{" "}
              <span className="text-bold text-hover"> 0890 111 439</span>
            </p>
            <h2 className="m-bottom-2 text-primary">Цветя и Подаръци</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-8">
              Подаръците не мислете, в плик ги донесете. Парите от цветя си спестете, благотворителната ни кауза подкрепете.
            </div>
            <h2 className="m-bottom-2 text-primary">Дрескод</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4">
              <div>
              </div>Дрескодът е напълно по желание, но ще се радваме още повече, ако искате да се включите с розово, синьо или лилаво!
              <div className="flex flex-align-center flex-justify-center m-top-2">
                <i class="fa-solid fa-heart p-1 text-hover"></i>
                <i class="fa-solid fa-heart p-1 text-primary"></i>
                <i class="fa-solid fa-heart p-1 text-third"></i>
              </div>
            </div>
            <h2 className="m-bottom-2 text-primary">Къде можем да нощуваме?</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4 flex flex-align-center">
              Ресторантът ни има хотелска част с капацитет за 21 човека с паркинг и предлага отстъпка за гостите на нашата сватба.
                <br />
                <br />
                  Цена за стандартна двойна стая на нощувка: 140лв.
                <br/>
                  Цена за Делукс / Тройна стая на нощувка: 200лв.
                <br />
                <br />
                Настаняване: след 14:00ч.
                <br />
                Освобождаване: преди 12:00ч. 
            </div>
            <h2 className="m-bottom-2 text-primary">Има ли уреден транспорт?</h2>
            <div className="text-lg p-right-10-xl p-left-10-xl m-bottom-4">
              Локацията ни се намира в центъра на Морската градина и тъй като цялата сватба е в един комплекс, не сме предвидили транспорт. В случай, че предпочетете друг хотел, препоръчваме ви да използвате:
            </div>
            <p className="m-bottom-1 text-lg">
              "Триумф такси" - тел.{" "}
              <span className="text-bold text-hover"> 052 644 444</span>
            </p>
            <p className="text-lg m-bottom-2">
              "Drink and Drive" - тел.{" "}
              <span className="text-bold text-hover"> 087 888 2100</span>
              {" "}(aко сте с кола)
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;
