import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

import engaged1 from "./images/engaged1.webp";
import engaged2 from "./images/engaged2.webp";
import engaged3 from "./images/engaged3.webp";
import engaged4 from "./images/engaged4.webp";
import engaged5 from "./images/engaged5.webp";
import engaged6 from "./images/engaged6.webp";
import engaged7 from "./images/engaged7.webp";
import engaged8 from "./images/engaged8.webp";
import engaged9 from "./images/engaged9.webp";

import BulgarianRose from "./images/bulgarian-rose.webp";

const images = [
  { url: engaged1 },
  { url: engaged2 },
  { url: engaged3 },
  { url: engaged4 },
  { url: engaged5 },
  { url: engaged6 },
  { url: engaged7 },
  { url: engaged8 },
  { url: engaged9 },
];

function EngagedSectionSlider({ width = "420", height = "600" }) {
  return (
    <div className="relative">
      <SimpleImageSlider
        width={width}
        height={height}
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        navMargin={8}
      />
      <div className="bulgarian-rose">
        <img
          style={{ width: "100%" }}
          src={BulgarianRose}
          alt="Bulgarian Rose"
        ></img>
      </div>
    </div>
  );
}

export default EngagedSectionSlider;
