import React, { useState } from "react";
import "./Header.scss";

function Header() {
  const [open, setOpen] = useState(false);
  const [openOnDesktop, setOpenOnDesktop] = useState(false);

  return (
    <nav className="navbar p-right-2-lg p-right-2-xl relative">
      <ul className="navbar-container flex flex-justify-end hidden-xs hidden-sm hidden-md">
        <li className="confirm-link-container m-right-3">
          <a className="flex flex-align-center confirm-link" onMouseEnter={() => setOpenOnDesktop(false)} href="#confirm">
            <i className="fa-solid fa-calendar-check m-right-1"></i>Потвърди присъствие
          </a>
        </li>
      </ul>

      <div className="navbar-container-sandwich-menu flex flex-justify-end cursor-pointer hidden-xs hidden-sm hidden-md">
        <i
          className="sandwich-menu fa-solid fa-bars"
          onMouseEnter={() => setOpenOnDesktop(true)}
        ></i>
        {openOnDesktop && (
          <div
            className="sandwich-menu-content p-top-5 shadow-default"
            onClick={() => setOpenOnDesktop(false)}
          >
            <i
              className="sandwich-menu sandwich-menu-opened fa-solid fa-xmark"
              onClick={() => setOpenOnDesktop(false)}
            />
            <div>
              <a className="flex flex-align-center font-weight-700" href="#schedule">
                <i className="fa-solid fa-clock m-right-1 text-secondary-hover-md text-secondary-hover-lg text-secondary-hover-xl"></i>Програма
              </a>
            </div>
            <hr />
            <div>
              <a className="flex flex-align-center font-weight-700" href="#transport">
                <i className="fa-solid fa-car m-right-1"></i>Транспорт
              </a>
            </div>
            <hr />
            <div>
              <a className="flex flex-align-center font-weight-700" href="#history">
                <i className="fa-solid fa-book m-right-1"></i>История
              </a>
            </div>
            <hr />
            <div>
              <a className="flex flex-align-center font-weight-700" href="#confirm">
                <i className="fa-solid fa-calendar-check m-right-1"></i>
                Потвърди
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="hidden-lg hidden-xl relative">
        <div className="navbar-container-mobile flex flex-justify-end">
          <i
            className="sandwich-menu fa-solid fa-bars"
            onClick={() => setOpen(true)}
          ></i>
          {open && (
            <div
              className="sandwich-menu-content p-top-5 shadow-default"
              onClick={() => setOpen(false)}
            >
              <i
                className="sandwich-menu sandwich-menu-opened fa-solid fa-xmark"
                onClick={() => setOpen(false)}
              />
              <div>
                <a className="flex flex-align-center" href="#schedule">
                  <i className="fa-solid fa-clock m-right-1"></i>Програма
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#transport">
                  <i className="fa-solid fa-car m-right-1"></i>Транспорт
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#history">
                  <i className="fa-solid fa-book m-right-1"></i>История
                </a>
              </div>
              <hr />
              <div>
                <a className="flex flex-align-center" href="#confirm">
                  <i className="fa-solid fa-calendar-check m-right-1"></i>
                  Потвърди
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;
