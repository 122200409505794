import React, { useEffect, useState } from "react";
import ConfirmForm from "./ConfirmForm";

function ConfirmationContainer() {
  const [guests, setGuests] = useState(0);

  useEffect(() => {}, [guests]);

  return (
    <section
      className="confirmation-container bg-primary p-top-8 p-bottom-8"
      id="confirm"
    >
      <div className="container">
        <h1 className="text-white text-center">ПОТВЪРДИ ПРИСЪСТВИЕ</h1>
        <div className="text-white text-center m-top-2 m-bottom-6 text-lg">
          Молим да потвърдите присъствието си до 15 Април, 2025 г.
        </div>
        <ConfirmForm />
        <h2 className="text-white text-center m-top-4 m-bottom-4">
          Искаш да потвърдиш за друг гост?
        </h2>

        {guests > 0 &&
          [...Array(guests)].map((item, index) => {
            return (
              <div key={index} className="m-top-3 m-bottom-3">
                <ConfirmForm />
              </div>
            );
          })}

        <div className="m-top-4 m-bottom-2 text-center">
          <button
            className="confirm-btn confirm-btn-inverse"
            onClick={() => setGuests(guests + 1)}
          >
            ДОБАВИ ГОСТ
          </button>
        </div>
      </div>
    </section>
  );
}

export default ConfirmationContainer;
