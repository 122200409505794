import React from "react";
import WelcomeDrinksImage from "./images/coctails.webp";

function WelcomeDrinks() {
  return (
    <div
      className="bg-third p-top-8 p-bottom-8"
      id="kokteilzadobredoshli"
    >
      <div className="container">
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <h2 className="flex flex-align-center flex-justify-center">
            <i className="fa-solid fa-house-lock m-right-1 text-xl text-base" />
            <span className="text-page">17:00</span>
          </h2>
          <h1 className="m-bottom-1 text-base">Коктейл за добре дошли</h1>
        </div>
        <div>
          <img
            alt="Kokteil za dobre doshli"
            className="display-block m-auto"
            src={WelcomeDrinksImage}
            style={{ width: "100%", maxWidth: 400 }}
          ></img>
        </div>
        <div className="col-12-xs col-12-sm col-12-md col-6-lg col-6-xl text-center m-auto">
          <p className="m-bottom-1 text-page m-bottom-2 text-justify m-top-1">
             Заповядайте в <span className="text-bold">
                {" "}Английския двор
              </span>,{" "} на комплекс <span className="text-bold">
                {" "}Хоризонт
              </span>,{" "} докато младоженците правят своята сватбена фотосесия. 
              </p>
              <p className="text-justify">
              п.с. Не ни търсете - ще си развалите изненадата! Вместо това можете да оползотворите времето си в сладки приказки с роднини и приятели. За ваше удобство сме подготвили напитки и хапки, но не преяждайте на този етап – по-късно ви очаква четиристепенно меню. 
              <br/><br/>Тук ви очаква и първата изненада! Преди да пристигне младата двойка, можете да играете <span className="text-bold">
                {" "}„Guess the Dress“
              </span> – по-късно ще видим дали някой е познал каква рокля ще носи булката. 
              </p>
        </div>
      </div>
    </div>
  );
}

export default WelcomeDrinks;
