import React from "react";
import EngagedSectionSlider from "./EngagedSectionSlider";
import imageEngaged from "./images/engagedDrawing.webp";

export default function RingsSection() {
  return (
    <div className="flex flex-align-center flex-justify-center m-top-6 m-bottom-6">
      <div className="col-12 p-top-3 p-bottom-3">
        <img
          alt="Engaged photo2"
          className="display-block m-auto"
          src={imageEngaged}
          style={{ width: "100%", maxWidth: 800 }}
        ></img>
        <div className="flex flex-justify-center m-top-2 m-bottom-2 text-sm text-justify" style={{ color: "#708090" }}><div className="col-8-xl col-12 "><p className="m-bottom-1">Отдалечени от тълпата, камерите и репотерите, Сашо застана на колене пред Лия и със затаен дъх ѝ продума: </p><p><i className="text-primary">"Един - пише по-добре, друг - рисува по-добре, трети - пее по-добре и за това хората се събират. Според мен това е най-голямото постижение на човечеството. Двойката. Защото заедно могат да постигнат повече, отколкото поотделно. И не говоря за роднините, при които си се родил, а тези, с които избереш да бъдеш. И в тази връзка, Лия, ще те запитам…"</i></p></div></div>
      </div>
      <div className="col-12 col-5-xl">
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-primary">
            Той:{" "}
          </div>
          <h4>Дали ще приемеш да бъдеш моя съпруга?
          </h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-2 text-hover">
            Тя:{" "}
          </div>
          <h4>Да!
          </h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-2">
          <div className="text-lg text-md-xs text-bold m-right-2 text-primary">
            Той:{" "}
          </div>
          <h4>Ама… Виж пръстена... Може да… размислиш?
          </h4>
        </div>
        <div className="flex flex-align-center flex-nowrap m-bottom-1">
          <div className="text-lg text-md-xs text-bold m-right-3 text-hover">
            Тя:{" "}
          </div>
          <h4>Не ми е за пръстена! … ах, много е хубав!</h4>
        </div>

        <div className="text-lg text-md-xs m-top-4">
          Бъдете с нас в комплекс Хоризонт на 25 Септември, 2025 г., за да чуе{" "}
          <span className="text-bold text-primary">ТОЙ</span> заветното{" "}
          <span className="text-bold text-primary">"ДА"</span> и за да бъде {" "}
          <span className="text-bold text-hover">ТЯ</span>{" "} най-щастливата жена!
          <br />
          <br />
          За нас ще бъде чест да присъствате.
          <br />
          <br />
          Молим да потвърдите вашето присъствие до{" "}
          <span className="text-primary text-bold">
            <br className="hidden-xs hidden-sm" />
            15 Април, 2025г.
          </span>{" "}
          като попълните формуляра в края на тази страница.
        </div>

        <div className="m-top-2 m-bottom-2 m-bottom-5-md m-top-4-sm m-top-4-md m-top-4-lg text-center-sm text-center-md text-center-lg">
          <a href="#confirm" className="confirm-btn full-width-xs">
            <i className="fa-solid fa-calendar-check m-right-1"></i>ПОТВЪРДИ{" "}
            <span className="hidden-xs">ПРИСЪСТВИЕ</span>
          </a>
        </div>
      </div>
      <div className="col-6 col-12-md col-12-lg col-5-xl p-left-2 p-left-no-xs flex flex-justify-center slider hidden-xs hidden-sm relative">
        <div className="engaged-sticky-slider">
          <EngagedSectionSlider width={420} height={600} />
          <h6 className="text-center m-top-1 col-12">05 Октомври, 2024г.</h6>
        </div>
      </div>
    </div>
  );
}
